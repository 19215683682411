//backend api with devise: p4-rails
export const API_URL = "https://p4-rails.herokuapp.com"

//--------------------------------------------------------------------------

//backend api without devise: project4-rails-api
    //export const API_URL = "http://project4-rails-api.herokuapp.com"

//--------------------------------------------------------------------------

//local backend rails server on port 4000
    // export const API_URL = 'http://localhost:4000'

//--------------------------------------------------------------------------
